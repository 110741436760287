import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  ApiErrorSchema,
  ApiMessageSchema,
  BadRequestErrorSchema,
  commonResponses,
  routeMetadata,
} from './common';

const c = initContract();

const WatchSchema = z.object({
  id: z.string(),
  name: z.string(),
  latitude: z.number().min(-90).max(90),
  longitude: z.number().min(-180).max(180),
  radius: z.number().min(5000).max(50000),
  addressLine1: z.string().optional(),
  addressLine2: z.string().optional(),
  notifyEmergency: z.boolean(),
  notifyWatchAct: z.boolean(),
  notifyAdvice: z.boolean(),
  notifyIncident: z.boolean(),
  notifyFloodAdvice: z.boolean(),
  notifyTsunamiAdvice: z.boolean(),
  notifySevereWeatherWatchAct: z.boolean(),
  notifySevereWeatherAdvice: z.boolean(),
});

type Watch = z.infer<typeof WatchSchema>;

const WatchInputSchema = WatchSchema.omit({
  id: true,
}).partial({
  notifyFloodAdvice: true,
  notifyTsunamiAdvice: true,
  notifySevereWeatherWatchAct: true,
  notifySevereWeatherAdvice: true,
});

type WatchInput = z.infer<typeof WatchInputSchema>;

const watchesContract = c.router(
  {
    create: {
      method: 'POST',
      path: '/watches',
      body: WatchInputSchema,
      responses: {
        200: z.object({
          id: z.string(),
        }),
        400: BadRequestErrorSchema,
      },
    },
    update: {
      method: 'PATCH',
      path: '/watches/:id',
      pathParams: z.object({
        id: z.string(),
      }),
      body: WatchInputSchema,
      responses: {
        200: z.object({
          id: z.string(),
        }),
        400: BadRequestErrorSchema,
      },
    },
    delete: {
      method: 'DELETE',
      path: '/watches/:id',
      pathParams: z.object({
        id: z.string(),
      }),
      body: null,
      responses: {
        200: ApiMessageSchema,
      },
    },
    get: {
      method: 'GET',
      path: '/watches/:id',
      pathParams: z.object({
        id: z.string(),
      }),
      responses: {
        200: WatchSchema,
        404: ApiErrorSchema,
      },
    },
    list: {
      method: 'GET',
      path: '/watches',
      responses: {
        200: z.array(WatchSchema),
      },
    },
  },
  {
    commonResponses,
    metadata: routeMetadata({ tags: ['Watches'] }),
  },
);

export type { Watch, WatchInput };
export { watchesContract, WatchInputSchema };
