import { initContract } from '@ts-rest/core';
import { z } from 'zod';

import {
  ApiMessageSchema,
  commonResponses,
  DeviceTypeSchema,
  routeMetadata,
} from './common';

const c = initContract();

const devicesContract = c.router(
  {
    register: {
      method: 'POST',
      path: '/devices/register',
      body: z.object({
        deviceToken: z.string(),
        deviceType: DeviceTypeSchema,
      }),
      responses: {
        200: ApiMessageSchema,
      },
    },
    update: {
      method: 'PATCH',
      path: '/devices/:deviceToken',
      pathParams: z.object({
        deviceToken: z.string(),
      }),
      body: z.object({
        latitude: z.number(),
        longitude: z.number(),
      }),
      responses: {
        200: ApiMessageSchema,
      },
    },
    deleteLocation: {
      method: 'DELETE',
      path: '/devices/:deviceToken/location',
      pathParams: z.object({
        deviceToken: z.string(),
      }),
      body: null,
      responses: {
        200: ApiMessageSchema,
      },
    },
  },
  {
    commonResponses,
    metadata: routeMetadata({ tags: ['Devices'] }),
  },
);

export { devicesContract };
