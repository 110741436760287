import { initContract } from '@ts-rest/core';

import { usersContract } from './usersContract';
import { devicesContract } from './devicesContract';
import { watchesContract } from './watchesContract';
import { notificationsContract } from './notificationsContract';
import { adminBroadcastsContract } from './adminBroadcastsContract';
import { adminPointsContract } from './adminPointsContract';
import { adminBroadcastTemplatesContract } from './adminBroadcastTemplatesContract';
import { adminIngestLogsContract } from './adminIngestLogsContract';
import { adminUsersContract } from './adminUsersContract';
import { adminMockFeedSettingsContract } from './adminMockFeedSettingsContract';
import { adminTransformRulesContract } from './adminTransformRulesContract';
import { adminAnalyticsContract } from './adminAnalyticsContract';
import { debugContract } from './debugContract';

export * from './common';
export * from './usersContract';
export * from './devicesContract';
export * from './watchesContract';
export * from './notificationsContract';
export * from './adminBroadcastsContract';
export * from './adminPointsContract';
export * from './adminBroadcastTemplatesContract';
export * from './adminIngestLogsContract';
export * from './adminUsersContract';
export * from './adminMockFeedSettingsContract';
export * from './adminTransformRulesContract';
export * from './adminAnalyticsContract';
export * from './debugContract';

export const allContracts = initContract().router({
  users: usersContract,
  devices: devicesContract,
  watches: watchesContract,
  notifications: notificationsContract,
  adminBroadcasts: adminBroadcastsContract,
  adminPoints: adminPointsContract,
  adminBroadcastTemplates: adminBroadcastTemplatesContract,
  adminIngestLogs: adminIngestLogsContract,
  adminUsers: adminUsersContract,
  adminMockFeedSettings: adminMockFeedSettingsContract,
  adminTransformRules: adminTransformRulesContract,
  adminAnalytics: adminAnalyticsContract,
  debug: debugContract,
});
export const adminContracts = initContract().router({
  adminBroadcasts: adminBroadcastsContract,
  adminPoints: adminPointsContract,
  adminBroadcastTemplates: adminBroadcastTemplatesContract,
  adminIngestLogs: adminIngestLogsContract,
  adminUsers: adminUsersContract,
  adminMockFeedSettings: adminMockFeedSettingsContract,
  adminTransformRules: adminTransformRulesContract,
  adminAnalytics: adminAnalyticsContract,
});
