import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { commonResponses, routeMetadata } from './common';

const c = initContract();

enum IngestLogMessageLevelEnum {
  INFO = 10,
  WARNING = 20,
  ERROR = 30,
}

const LogTypeSchema = z.enum(['Fire', 'Flood', 'Fire Ban']);
const LogMessageSchema = z.object({
  level: z.nativeEnum(IngestLogMessageLevelEnum),
  message: z.string(),
  createdAt: z.string(),
});
const IngestLogSchema = z.object({
  name: z.string(),
  type: LogTypeSchema,
  level: z.nativeEnum(IngestLogMessageLevelEnum).optional(),
  messages: z.array(LogMessageSchema),
  sources: z.record(z.string(), z.string()).optional(),
  createdAt: z.number(),
});

type IngestLog = z.infer<typeof IngestLogSchema>;

const adminIngestLogsContract = c.router(
  {
    list: {
      path: '/admin/logs',
      method: 'GET',
      responses: {
        200: z.array(IngestLogSchema),
      },
      metadata: routeMetadata({
        permissions: ['admin:logs:read'],
      }),
    },
    source: {
      path: '/admin/logs/source',
      method: 'GET',
      query: z.object({
        key: z.string(),
      }),
      responses: {
        200: z.object({
          source: z.string().optional(),
        }),
      },
      metadata: routeMetadata({
        permissions: ['admin:logs:read'],
      }),
    },
  },
  {
    commonResponses,
    metadata: routeMetadata({
      tags: ['Admin/Ingest Logs'],
    }),
  },
);

export type { IngestLog };
export { adminIngestLogsContract, IngestLogMessageLevelEnum };
